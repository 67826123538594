<template>
    <div>
        <div class="box">
            <h2>{{ $t('gocardless.mandates') }}</h2>

            <template v-if="enable_add_mandate">
                {{ $t('gocardless.add_mandate') }}<br>
                <button :disabled="loading_add_mandate" class="btn btn-primary" type="button" @click.prevent="add_mandate()">
                    <font-awesome-icon v-if="loading_add_mandate" :icon="['far', 'spinner']" spin />
                    {{ $t('global.ajouter') }}
                </button>
            </template>

            <LoadingSpinner class="col-12" v-if="loading" />
            <template v-else>
                <CustomTable
                    id_table="gocardless_mandate"
                    :items="mandates"
                    sortBy="reference"
                    primaryKey="id"
                    :hasExportOption="false"
                    :showPagination="false"
                    :display_action_button="false"
                    :checkboxes="false"
                />
            </template>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Gocardless from '@/mixins/Gocardless.js'
    
    export default {
        name: "GocardlessMandate",
        mixins: [Gocardless],
        data () {
            return {
                loading: false,
                enable_add_mandate: false,
                loading_add_mandate: false,
                mandates: []
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loading = true
                const man = await this.getMandates()
                this.mandates = this.format_mandate(man)
                if(!man) {
                    this.enable_add_mandate = true
                }

                this.loading = false
            },
            format_mandate(mandates) {
                let m = []
                if(!mandates) {
                    return []
                }

                for(let i = 0; i < mandates.length; i++) {
                    let temp = mandates[i]
                    temp.status = this.getTrad('gocardless.mandates_'+mandates[i].status)
                    m.push(temp)
                }

                return m
            },
            async add_mandate() {
                this.loading_add_mandate = true
                const mandate = await this.addMandate()
                if(mandate) {
                    window.location = mandate.redirect_url
                } else {
                    this.loading_add_mandate = false
                }
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		    CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
